<template>
  <div class="platform-check" ref="tourismStatistics">
    <div class="function-buttons" ref="functionButtons">
      <el-button type="primary" size="small" @click="onAddTour">提问</el-button>
    </div>
    <el-table :data="tableData" stripe :height="tableHeight">
      <el-table-column type="index" label="序号"></el-table-column>
      <el-table-column
        prop="createTime"
        label="查岗时间"
        show-overflow-tooltip
      ></el-table-column>

      <el-table-column
        prop="question"
        label="问题"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="userName"
        label="发起人"
        show-overflow-tooltip
      ></el-table-column>

      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <el-button
            @click="onViewTour(scope.row)"
            type="text"
            size="small"
            sort="primary"
            >详情</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination" ref="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
    <!--新增 -->
    <el-dialog
      :title="title"
      v-if="dialogVisible"
      :visible.sync="dialogVisible"
      :width="disabledShow ? '60%' : '40%'"
      @close="onDialogClose"
    >
      <!-- 提问 -->
      <el-form
        ref="form"
        :model="form"
        label-position="left"
        label-width="82px"
        v-if="!disabledShow"
      >
        <el-form-item label="提问公司：">
          <companySelectForSearch
            ref="companySelectForSearch"
            :searchable="true"
            @getValue="getGroupIds"
          />
        </el-form-item>
        <el-form-item label="抽查问题：">
          <el-input
            type="textarea"
            :rows="6"
            placeholder="请输入问题"
            v-model="form.question"
            style="width: 200px"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <!-- 详情 -->
      <div class="detail" v-else>
        <div class="item">
          <span>提问公司：{{ itemInfo.companyName }}</span>
          <span>抽查问题：{{ itemInfo.question }}</span>
        </div>
        <div class="item">
          <span>抽查时间：{{ itemInfo.createTime }}</span>
          <span>发起人：{{ itemInfo.userName }}</span>
        </div>
        <p>抽查详情</p>
        <el-table :data="tableDataInfo" stripe height="46vh">
          <el-table-column
            type="index"
            label="序号"
            width="60"
          ></el-table-column>
          <el-table-column
            prop="companyName"
            label="所属公司"
            show-overflow-tooltip
          ></el-table-column>

          <el-table-column
            prop="restoreUserName"
            label="回复人"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="restore"
            label="回复内容"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="restoreTime"
            label="回复时间"
            show-overflow-tooltip
          ></el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="onDialogClose"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="small"
          @click="onSave()"
          :loading="editLoading"
          v-show="!disabledShow"
          >提问</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import carTree from '@/components/carTree/carSearchTree.vue'
import companySelectForSearch from '@/components/treeSelect/companySelectForSearch.vue'

import { platformQuestionInsertApi, platformQuestionPageApi, platformQuestionInfoApi } from '@/api/lib/gps-api.js'


export default {
  name: 'tourismStatistics',
  components: { carTree, companySelectForSearch },
  data () {

    return {
      tableHeight: 0,
      total: 0,
      tableData: [],
      tableDataInfo: [],
      form: {
        companyId: '',
        question: '',
      },
      formSearch: {
        pageSize: 10,
        currentPage: 1,
      },
      timeLimit: 60,
      title: '',
      dialogVisible: false,
      editLoading: false,
      disabledShow: false,//查看时所有的禁用掉
      itemInfo: null,
    }
  },
  methods: {
    // 计算表格高度
    computeHeight () {
      const wholeHeight = this.$refs.tourismStatistics.clientHeight
      const buttonsHeight = this.$refs.functionButtons.clientHeight
      const paginationHeight = this.$refs.pagination.clientHeight
      this.tableHeight =
        wholeHeight -
        16 -
        buttonsHeight -
        16 -
        paginationHeight -
        10 -
        16
    },
    // 获取车组数据
    getGroupIds (val) {
      this.form.companyId = val[0];
    },
    //获取数据
    getListByField () {
      platformQuestionPageApi(this.formSearch).then(res => {
        if (res.code === 1000) {
          this.tableData = res.data.list || [];
          this.total = res.data.total;
        }
      })
    },
    // 当前页码改变
    onCurrentChange (page) {
      this.formSearch.currentPage = page;
      this.getListByField();
    },

    // 当前条数改变
    onSizeChange (size) {
      this.formSearch.pageSize = size;
      this.getListByField();
    },
    // 新增
    onAddTour () {
      this.title = '平台查岗提问'
      this.itemId = null
      this.dialogVisible = true
      this.disabledShow = false;
    },

    // 查看
    onViewTour (row) {
      this.title = '平台查岗提问详情';
      platformQuestionInfoApi({
        questionId: row.id
      }).then(res => {
        if (res.code === 1000) {
          console.log('----', res);
          this.itemInfo = res.data;
          this.tableDataInfo = res.data.detailList || [];
        }
      })
      this.dialogVisible = true
      this.disabledShow = true;
    },
    //  保存
    onSave () {
      if (!this.form.companyId) {
        this.$message.warning('请选择提问公司')
        return
      }
      if (!this.form.question) {
        this.$message.warning('请填写查岗问题')
        return
      }
      const { companyId, question } = this.form;
      this.editLoading = true;
      platformQuestionInsertApi({
        companyId, question
      }).then(res => {
        console.log("res", res);
        if (res.code === 1000) {
          this.$message.success(res.msg);
          this.onDialogClose();
          this.getListByField();
        } else {
          this.$message.error(res.msg);
          this.editLoading = false;
        }
      }).catch(() => {
        this.editLoading = false;
      })
    },
    // 弹窗关闭时的回调
    onDialogClose () {
      this.dialogVisible = false;
      this.form.question = "";
      this.editLoading = false;
    },
  },
  created () {

  },
  mounted () {
    this.$nextTick(() => {
      this.computeHeight();
      this.getListByField();
      window.addEventListener('resize', this.computeHeight, false)
    })
  },
  destroyed () {
    window.removeEventListener('resize', this.computeHeight)
  }
}
</script>

<style lang="scss" scoped>
.platform-check {
  height: 100%;
  width: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  .detail {
    .item {
      width: 80%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;
      color: #333;
    }
    p {
      color: #000;
      margin: 16px 0 12px;
    }
  }
}
.theme-project-gps {
  .platform-check {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
</style>
