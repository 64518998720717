var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "tourismStatistics", staticClass: "platform-check" },
    [
      _c(
        "div",
        { ref: "functionButtons", staticClass: "function-buttons" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.onAddTour }
            },
            [_vm._v("提问")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        { attrs: { data: _vm.tableData, stripe: "", height: _vm.tableHeight } },
        [
          _c("el-table-column", { attrs: { type: "index", label: "序号" } }),
          _c("el-table-column", {
            attrs: {
              prop: "createTime",
              label: "查岗时间",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "question",
              label: "问题",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "userName",
              label: "发起人",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small", sort: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.onViewTour(scope.row)
                          }
                        }
                      },
                      [_vm._v("详情")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { ref: "pagination", staticClass: "pagination" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
              "page-size": _vm.form.pageSize,
              "current-page": _vm.form.currentPage
            },
            on: {
              "size-change": _vm.onSizeChange,
              "current-change": _vm.onCurrentChange
            }
          })
        ],
        1
      ),
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.title,
                visible: _vm.dialogVisible,
                width: _vm.disabledShow ? "60%" : "40%"
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogVisible = $event
                },
                close: _vm.onDialogClose
              }
            },
            [
              !_vm.disabledShow
                ? _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.form,
                        "label-position": "left",
                        "label-width": "82px"
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "提问公司：" } },
                        [
                          _c("companySelectForSearch", {
                            ref: "companySelectForSearch",
                            attrs: { searchable: true },
                            on: { getValue: _vm.getGroupIds }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "抽查问题：" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "200px" },
                            attrs: {
                              type: "textarea",
                              rows: 6,
                              placeholder: "请输入问题"
                            },
                            model: {
                              value: _vm.form.question,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "question", $$v)
                              },
                              expression: "form.question"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _c(
                    "div",
                    { staticClass: "detail" },
                    [
                      _c("div", { staticClass: "item" }, [
                        _c("span", [
                          _vm._v(
                            "提问公司：" + _vm._s(_vm.itemInfo.companyName)
                          )
                        ]),
                        _c("span", [
                          _vm._v("抽查问题：" + _vm._s(_vm.itemInfo.question))
                        ])
                      ]),
                      _c("div", { staticClass: "item" }, [
                        _c("span", [
                          _vm._v("抽查时间：" + _vm._s(_vm.itemInfo.createTime))
                        ]),
                        _c("span", [
                          _vm._v("发起人：" + _vm._s(_vm.itemInfo.userName))
                        ])
                      ]),
                      _c("p", [_vm._v("抽查详情")]),
                      _c(
                        "el-table",
                        {
                          attrs: {
                            data: _vm.tableDataInfo,
                            stripe: "",
                            height: "46vh"
                          }
                        },
                        [
                          _c("el-table-column", {
                            attrs: { type: "index", label: "序号", width: "60" }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "companyName",
                              label: "所属公司",
                              "show-overflow-tooltip": ""
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "restoreUserName",
                              label: "回复人",
                              "show-overflow-tooltip": ""
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "restore",
                              label: "回复内容",
                              "show-overflow-tooltip": ""
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "restoreTime",
                              label: "回复时间",
                              "show-overflow-tooltip": ""
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "default", size: "small" },
                      on: { click: _vm.onDialogClose }
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.disabledShow,
                          expression: "!disabledShow"
                        }
                      ],
                      attrs: {
                        type: "primary",
                        size: "small",
                        loading: _vm.editLoading
                      },
                      on: {
                        click: function($event) {
                          return _vm.onSave()
                        }
                      }
                    },
                    [_vm._v("提问")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }